
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"

export default {
  components: {
    EvercamLoadingAnimation,
  },
  props: {
    chatHistory: {
      type: Array,
      required: true,
    },
    isProcessing: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      displayResponse: "",
      completedTyping: false,
      headers: [
        {
          value: "hazardCategory",
          text: "Hazard Category",
          width: 50,
          sortable: true,
          filterable: true,
        },
        {
          value: "concerns",
          text: "Concerns",
          width: 75,
          filterable: false,
          sortable: false,
        },
        {
          value: "actions",
          text: "Actions / Checklist items",
          width: 120,
          filterable: false,
          sortable: false,
        },
      ],
    }
  },
}
